import { Application, EnvAccount } from '../types';
import { VConsole } from './3rd';
import Consts from './consts';
import DOM from './dom';
import Storage from './storage';
import Utils from './utils';
import * as HostBy from './host-by';

type OS = {
    android?: boolean;
    ios?: boolean;
    iphone?: boolean;
    ipad?: boolean;
    ipod?: boolean;
    wp?: boolean;
    webos?: boolean;
    touchpad?: boolean;
    blackberry?: boolean;
    bb10?: boolean;
    rimtabletos?: boolean;
    kindle?: boolean;
    firefoxos?: boolean;
    version?: string;
    tablet?: boolean;
    phone?: boolean;
    desk?: boolean;
    app?: boolean;
    wechat?: boolean;
    wechatMini?: boolean;
    eplbks?: boolean;
    eplbksVersion?: string;
};
type BROWSER = {
    webkit?: boolean;
    playbook?: boolean;
    silk?: boolean;
    chrome?: boolean;
    firefox?: boolean;
    ie?: boolean;
    edge?: boolean;
    safari?: boolean;
    webview?: boolean;
    version?: string;
};

/**
 * 环境类
 */
class Envs {
    // 声明
    private rootApp: any;
    os: OS;
    browser: BROWSER;
    private vconsole: any;
    standardThemeName: string = 'standard';

    constructor() {
        this.os = {};
        this.browser = {};
        this.standardThemeName = 'standard';
        this.detect();
        this.bounceOfBlur();
        this.initVConsole();
        this.handleAndroidPhysicalBackButtonPressed();
    }

    /**
     * 设置application对象
     */
    application(app?: any): Application | null | undefined {
        if (!Utils.isUndefined(app)) {
            this.rootApp = app;
        } else {
            return this.rootApp;
        }
    }

    /**
     * get current theme name
     */
    getCurrentTheme(): string {
        const queryString = Utils.fromQueryString();
        if (queryString && queryString.theme) {
            // URL指定了主题
            return queryString.theme;
        } else {
            // 从Storage获取主题
            return Storage.Theme.get(Consts.THEME_KEY);
        }
    }

    getCurrentThemeColor(): string {
        const queryString = Utils.fromQueryString();
        if (queryString && !Utils.isBlank(queryString.themeColor)) {
            if (new RegExp("[0-9a-zA-Z]{6}").test(queryString.themeColor)) {
                return `#${queryString.themeColor}`;
            }
            return Storage.Theme.get(Consts.THEME_COLOR_KEY);
        } else {
            return Storage.Theme.get(Consts.THEME_COLOR_KEY);
        }
    }


	/**
	 * 判断是否是TAT token
	 */
	isTatToken(ticket?: string): boolean {
		if (ticket) {
			return ticket.indexOf('TAT_') !== -1;
		}
		const queryString = Utils.fromQueryString();
		return queryString.ticket != null && queryString.ticket.indexOf('TAT_') !== -1;
	}

	/**
	 * 判断是否是免登录形态 (即url是否携带免登陆信息)
	 */
	isStandaloneAndDirectAccess(): boolean {
		const queryString = Utils.fromQueryString();
		return this.isTatToken() || (queryString.ticket && queryString.delegated) != null;
	}

    /**
     * get relative path, add context prefix
     *
     * @param relativePath 必须以"/"开头
     */
    getContextLocation(relativePath: string, options?: {
        protocolHost?: boolean;
        host?: string;
    }): string {
        const { protocolHost = false, host } = options || {};
        let contextPath;
        let path = window.location.pathname;
        let secondIndex = path.indexOf('/', 1);
        if (secondIndex === -1) {
            // no context path
            contextPath = process.env.REACT_APP_AJAX_CLIENT_CONTEXT || '/csms2';
        } else {
            contextPath = path.substring(0, secondIndex);
        }
        let result = '';
        if (relativePath) {
            result = `${contextPath}${relativePath}`;
        } else {
            result = contextPath;
        }
        if (protocolHost) {
            if (Utils.isBlank(host)) {
                result = `${window.location.protocol}//${window.location.host}${result}`;
            } else {
                result = `${window.location.protocol}//${host}${result}`;
            }
        }
        return result;
    }

    private handleAndroidPhysicalBackButtonPressed(): void {
        (window as any).onAndroidReturn = () => {
            window.history.back();
        };
    }

    /**
     * copy from https://github.com/madrobby/zepto/blob/master/src/detect.js
     * 侦测环境
     */
    private detect(): void {
        const ua = window.navigator.userAgent;
        const platform = window.navigator.platform;

        const os: OS = this.os,
            browser: BROWSER = this.browser,
            // eslint-disable-next-line
            webkit: any = ua.match(/Web[kK]it[\/]{0,1}([\d.]+)/),
            // eslint-disable-next-line
            android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
            // mac
            // eslint-disable-next-line
            osx = !!ua.match(/\(Macintosh\; Intel /) || ua.match(/(Mac)/),
            ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
            ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
            iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
            // eslint-disable-next-line
            webos = ua.match(/(webOS|hpwOS)[\s\/]([\d.]+)/),
            win = /Win\d{2}|Windows/.test(platform) || ua.match(/(win)/),
            wp = ua.match(/Windows Phone ([\d.]+)/),
            touchpad = webos && ua.match(/TouchPad/),
            kindle = ua.match(/Kindle\/([\d.]+)/),
            silk = ua.match(/Silk\/([\d._]+)/),
            blackberry = ua.match(/(BlackBerry).*Version\/([\d.]+)/),
            bb10 = ua.match(/(BB10).*Version\/([\d.]+)/),
            rimtabletos = ua.match(/(RIM\sTablet\sOS)\s([\d.]+)/),
            playbook = ua.match(/PlayBook/),
            chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
            firefox = ua.match(/Firefox\/([\d.]+)/),
            firefoxos = ua.match(/\((?:Mobile|Tablet); rv:([\d.]+)\).*Firefox\/[\d.]+/),
            ie =
                ua.match(/MSIE\s([\d.]+)/) ||
                // eslint-disable-next-line
                ua.match(/Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/),
            edge = ua.match(/(edge)\/([\w.]+)/),
            appleWebview = !chrome && ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
            safari = appleWebview || ua.match(/Version\/([\d.]+)([^S](Safari)|[^M]*(Mobile)[^S]*(Safari))/),
            linux = ua.match(/(Linux)/),
            // chrome os
            cros = ua.match(/(CrOS)/),
            androidWebview = ua.match(/(wv\))/),
            eplbks = ua.toLowerCase().match(/(eplbks)\/(\d+[.\d+]+)/);

        if ((browser.webkit = !!webkit)) {
            browser.version = webkit[1];
        }

        if (eplbks) {
            os.eplbks = true;
            os.eplbksVersion = eplbks[2];
        }

        if (android) {
            os.android = true;
            os.version = android[2];
        }
        if (iphone && !ipod) {
            os.ios = os.iphone = true;
            os.version = iphone[2].replace(/_/g, '.');
        }
        if (ipad) {
            os.ios = os.ipad = true;
            os.version = ipad[2].replace(/_/g, '.');
        }
        if (ipod) {
            os.ios = os.ipod = true;
            os.version = ipod[3] ? ipod[3].replace(/_/g, '.') : undefined;
        }
        if (wp) {
            os.wp = true;
            os.version = wp[1];
        }
        if (webos) {
            os.webos = true;
            os.version = webos[2];
        }
        if (touchpad) {
            os.touchpad = true;
        }
        if (blackberry) {
            os.blackberry = true;
            os.version = blackberry[2];
        }
        if (bb10) {
            os.bb10 = true;
            os.version = bb10[2];
        }
        if (rimtabletos) {
            os.rimtabletos = true;
            os.version = rimtabletos[2];
        }
        if (playbook) {
            browser.playbook = true;
        }
        if (kindle) {
            os.kindle = true;
            os.version = kindle[1];
        }
        if (silk) {
            browser.silk = true;
            browser.version = silk[1];
        }
        if (!silk && os.android && ua.match(/Kindle Fire/)) {
            browser.silk = true;
        }
        if (chrome) {
            browser.chrome = true;
            browser.version = chrome[1];
        }
        if (firefox) {
            browser.firefox = true;
            browser.version = firefox[1];
        }
        if (firefoxos) {
            os.firefoxos = true;
            os.version = firefoxos[1];
        }
        if (ie) {
            browser.ie = true;
            browser.version = ie[1];
        }
        if (edge) {
            browser.edge = true;
        }
        if (safari && (osx || os.ios || win)) {
            browser.safari = true;
            if (!os.ios) {
                browser.version = safari[1];
            }
        }

        os.tablet = !!(
            ipad ||
            playbook ||
            (android && !ua.match(/Mobile/)) ||
            (firefox && ua.match(/Tablet/)) ||
            (ie && !ua.match(/Phone/) && ua.match(/Touch/))
        );
        os.phone = !!(
            !os.tablet &&
            !os.ipod &&
            (android ||
                iphone ||
                webos ||
                blackberry ||
                bb10 ||
                (chrome && ua.match(/Android/)) ||
                (chrome && ua.match(/CriOS\/([\d.]+)/)) ||
                (firefox && ua.match(/Mobile/)) ||
                (ie && ua.match(/Touch/)))
        );
        os.desk = !!(osx || cros || win || linux) && !os.tablet && !os.phone;

        // 是否是易保App
        if (/ebao/i.test(ua)) {
            os.app = true;
        }
        // 是否是永达理新App
        if (/eplbks/i.test(ua)) {
            os.app = true;
        }
        //是否中融的app,安卓与ios
        if (/v_ios_app/i.test(ua) || /v_android_app/i.test(ua)) {
            os.app = true;
        }
        // 江泰
        if (/appName\/sxj/i.test(ua)) {
            os.app = true;
        }
        // 壹心 鲸云保
        if (/yxinsur/i.test(ua)) {
            os.app = true;
        }
        if (appleWebview || androidWebview || os.app) {
            browser.webview = true;
        }
        // 是否是微信
        if (/MicroMessenger/i.test(ua)) {
            os.wechat = true;
        }
        // 是否是微信小程序
        // @ts-ignore
        if (window.__wxjs_environment === 'miniprogram') {
            os.wechatMini = true;
        }
    }

    /**
     * 是否正在桌面环境下
     */
    isDesk(): boolean {
        return this.os.desk === true;
    }

    /**
     * 是否正在手机环境下
     */
    isPhone(): boolean {
        return this.os.phone === true;
    }

    /**
     * 是否正在平板环境下
     */
    isTablet(): boolean {
        return this.os.tablet === true;
    }

    /**
     * 是否正在iphone环境下
     */
    isIphone(): boolean {
        return this.os.iphone === true;
    }

    /**
     * 是否正在安卓环境下
     */
    isAndroid(): boolean {
        return this.os.android === true;
    }

    /**
     * 是否正在微信环境下
     */
    isWechat(): boolean {
        return this.os.wechat === true;
    }

    /**
     * 是否正在微信小程序环境下
     */
    isWechatMiniProgram(): boolean {
        return this.os.wechatMini === true;
    }

    /**
     * 是否在原生app中运行, 注意并不是所有的app都可以侦测到
     */
    isNativeApp(): boolean {
        return this.os.app === true;
    }

    /**
     * 是否在永达理新原生app中运行
     */
    isEPLBKSApp(): boolean {
        console.log(`eplbks new app:`, this.os.eplbks);
        return this.os.eplbks === true;
    }
    /** 获取永达理app版本号 */
    getAppEplbksVersion(): string {
        console.log(`eplbks app eplbksVersion:`, this.os.eplbksVersion);
        return this.os.eplbksVersion || '';
    }

    isHKXGZX(): boolean {
        const url = window.location.pathname;
        if (window.location.pathname.indexOf('HKXGZX') > -1) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * 是否在中融app中运行,
     */
    isZrhuijinApp(): boolean {
        return this.os.app === true;
    }

    /**
     * 指定的用户是否是系统管理员?
     * 没有指定用户, 使用当前用户
     */
    isSystemOwnerAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        const tenantId = account.tenantId;
        return tenantId + '' === Consts.TENANT_IDS.SYSTEM_OWNER && this.isTenantAdmin(account);
    }

    /**
     * 指定的用户是否是租户管理员?
     * 没有指定用户, 使用当前用户
     */
    isTenantAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        return !account.organizationId && this.isAdmin(account);
    }

    /**
     * 指定的用户是否是业务租户管理员(不是系统管理员, 也不是虚拟租户管理员)?
     * 没有指定用户, 使用当前用户
     */
    isBizTenantAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        const tenantId = account.tenantId + '';
        return (
            this.isTenantAdmin(account) &&
            tenantId !== Consts.TENANT_IDS.SYSTEM_OWNER &&
            tenantId !== Consts.TENANT_IDS.VIRTUAL
        );
    }

    /**
     * 指定的用户是否是机构管理员?
     * 没有指定用户, 使用当前用户
     */
    isOrganAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        return account.organizationId != null && this.isAdmin(account);
    }

    /**
     * 指定的用户是否是管理员?
     * 没有指定用户, 使用当前用户
     */
    isAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        return account.type + '' === Consts.ACCOUNT_TYPE.ADMIN;
    }

    /**
     * 指定的用户是否是系统管理员(不是系统管理员, 也不是虚拟租户下的管理员)?
     * 没有指定用户, 使用当前用户
     */
    isBizAdmin(account?: EnvAccount): boolean {
        account = account || this.findAccount();
        const tenantId = account.tenantId + '';
        return (
            this.isAdmin(account) &&
            tenantId !== Consts.TENANT_IDS.SYSTEM_OWNER &&
            tenantId !== Consts.TENANT_IDS.VIRTUAL
        );
    }

    /**
     * 指定的用户是否在某个机构下?
     * 没有指定用户, 使用当前用户
     */
    isCurrentAccountUnderOrgan(account?: EnvAccount): boolean {
        return (account || this.findAccount()).organizationId != null;
    }

    /**
     * 指定的用户是否在虚拟租户下?
     * 没有指定用户, 使用当前用户
     */
    isVirtualTenant(account?: EnvAccount): boolean {
        return (account || this.findAccount()).tenantId + '' === Consts.TENANT_IDS.VIRTUAL;
    }

    /**
     * 当前用户是否拥有指定菜单的授权
     *
     * @param code 菜单代码
     */
    isMenuAuthorized(code: string): boolean {
        if (this.isSystemOwnerAdmin()) {
            return true;
        }
        const menus = (this.findAccount().menus || '').split(',');
        return menus.indexOf(code) !== -1;
    }

    /**
     * 获取当前用户信息
     */
    findAccount(): EnvAccount {
        return Storage.Account.session().get(Consts.ACCOUNT_KEY) || {};
    }

    isAuthorized(): boolean {
        return !!this.findAccount().accountName;
    }

    /**
     * 保存账户信息
     */
    holdAccount(account: EnvAccount): void {
        Storage.Account.local().set(Consts.ACCOUNT_SIGN_KEY, account.accountName);
        Storage.Account.session().set(Consts.ACCOUNT_KEY, account);
    }

    /**
     * 指定的元素是否会使用软键盘
     */
    private deserveSoftKeyboard(element: Element | EventTarget | null): boolean {
        if (element == null) {
            return false;
        }

        const tagName = (element as Element).tagName.toUpperCase();
        const tagType = ((element as Element).getAttribute('type') || '').toUpperCase();

        return (
            ['SELECT', 'TEXTAREA'].includes(tagName) || ('INPUT' === tagName && ['TEXT', 'PASSWORD'].includes(tagType))
        );
    }

    /**
     * 监听焦点事件, 修复苹果+微信回弹问题
     * 非微信环境也有可能出现此情况
     */
    private bounceOfBlur = () => {
        // 根据手机真实尺寸计算高度, 但是ios测试有问题.
        // 代码保留, 但是不使用
        // const compute = () => {
        // 	const vh = window.innerHeight * 0.01;
        // 	document.documentElement.style.setProperty('--vh', `${vh}px`);
        // };
        // window.addEventListener('resize', compute);
        // compute();

        // 微信+iOS软键盘收起不能正确回弹, 强制回弹页面
        document.addEventListener(
            'blur',
            (evt: FocusEvent) => {
                if (!this.deserveSoftKeyboard(evt.target)) {
                    return;
                }

                const currentScrollTop =
                    document.documentElement!.scrollTop || window.pageYOffset || document.body.scrollTop;

                // 50毫秒后执行
                setTimeout(() => {
                    if (
                        this.isIphone() &&
                        //	&& this.isWechat()
                        currentScrollTop !== 0
                    //	&& !this.deserveSoftKeyboard(document.activeElement)
                    ) {
                        // 强制回弹
                        const body = document.body;
                        const bodyHeight = DOM.outerHeight(body, true);

                        if (currentScrollTop + window.innerHeight > bodyHeight) {
                            body.scrollTop = currentScrollTop - (currentScrollTop + window.innerHeight - bodyHeight);
                            document.documentElement!.scrollTop =
                                currentScrollTop - (currentScrollTop + window.innerHeight - bodyHeight);
                        }
                    }
                }, 50);
            },
            true
        );

        //监听窗口大小变化事件,输入框在可视化区域
        window.addEventListener('resize', function (evt) {
            const element = evt.target;
            if ((element as Element).tagName === 'INPUT') {
                (element as Element).scrollIntoView({ behavior: "smooth" })
            }
        });

    };
    private initVConsole = () => {
        if (this.isDesk()) {
            // 桌面不需要
            return;
        }

        let count = 0;
        const countFingers = (evt: TouchEvent, touchesLength: number) => evt.touches.length === touchesLength;
        const isOnCanvas = (evt: Event) => (evt.target as Element).tagName === 'CANVAS';
        const addListener = (evtName: string, handler: EventListener) =>
            window.addEventListener(evtName, handler, false);
        const addStartListener = (handler: EventListener) => addListener('touchstart', handler);
        const removeListener = (evtName: string, handler: EventListener) =>
            window.removeEventListener(evtName, handler);

        const handleFirst3: EventListener = (evt: Event) => {
            // 第一次三指按下, 计数器归零
            count = 0;
            if (!isOnCanvas(evt) && countFingers(evt as TouchEvent, 3)) {
                // 移除自己
                removeListener('touchstart', handleFirst3);
                // 监听下一次四指, 即增加一指, 因此是touchstart
                addStartListener(handleAfter3);
            }
        };
        // 构造三指后的监听器
        const handleAfter3: EventListener = (evt: Event) => {
            // 移除自己handleFirst3
            removeListener('touchstart', handleAfter3);
            if (!isOnCanvas(evt) && countFingers(evt as TouchEvent, 4)) {
                // 四指触发
                // 监听下一次三指, 即释放一指, 因此是touchend
                addListener('touchend', handleAfter4);
            } else {
                // 结束
                count = 0;
                // 重新开始监听第一次三指按下
                addStartListener(handleFirst3);
            }
        };
        // 构造四指后的监听器
        const handleAfter4: EventListener = (evt: Event) => {
            // 移除自己
            removeListener('touchend', handleAfter4);
            if (!isOnCanvas(evt) && countFingers(evt as TouchEvent, 3)) {
                // 三指触发
                count++;
                if (count >= 3) {
                    // 第三次循环, 即三指->四指->三指->四指->三指->四指->三指
                    count = 0;
                    if (this.vconsole == null) {
                        const show = async () => {
                            this.vconsole = new (await VConsole()).default();
                            this.vconsole.hideSwitch();
                            this.vconsole.show();
                        };
                        show();
                    } else {
                        this.vconsole.show();
                    }
                    // 重新开始监听第一次三指按下
                    addStartListener(handleFirst3);
                } else {
                    // 监听下一次四指, 即增加一指, 因此是touchstart
                    addStartListener(handleAfter3);
                }
            } else {
                // 结束
                count = 0;
                // 重新开始监听第一次三指按下
                addStartListener(handleFirst3);
            }
        };

        // 第一步, 监听三指按下
        addStartListener(handleFirst3);
    };

    getServiceLocation(relativePath?: string): string {
        let url = window.location;

        let port = url.port;
        if (process.env.REACT_APP_AJAX_SERVER_PORT) {
            port = `:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
        } else if (port) {
            port = `:${port}`;
        }
        let hostname = url.hostname;
        if (process.env.REACT_APP_AJAX_SERVER_HOST) {
            hostname = process.env.REACT_APP_AJAX_SERVER_HOST;
        }
        let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || '/csms';
        let location = `${url.protocol}//${hostname}${port}${context}`;
        if (relativePath) {
            return location + relativePath;
        } else {
            return location;
        }
    }

    getEnvValue(key: string): string | undefined {
        // property需要以REACT_APP_开头才能读取
        let reactPrefix = 'REACT_APP_';
        let fixedKey = reactPrefix + key;
        if (process.env.NODE_ENV === 'development') {
            // 本地开发环境
            return process.env[fixedKey];
        } else {
            let suffix = '';
            if (process.env.REACT_APP_ENV_NAME) {
                suffix = process.env.REACT_APP_ENV_NAME;
            } else {
                // 其他环境
                const host = window.location.hostname;
                const first = host.split('.')[0];
                if (first === 'csms-prod') {
                    // 生产
                    suffix = 'PROD';
                } else if (first === 'csms-uat') {
                    // UAT
                    suffix = 'UAT';
                } else if (first === 'csms-sit') {
                    // 内部集成测试SIT
                    suffix = 'SIT';
                } else if (first === 'csms-dev') {
                    // 内部开发
                    suffix = 'DEV';
                }
            }
            let value = process.env[`${fixedKey}_${suffix}`];
            if (!value) {
                value = process.env[fixedKey];
            }
            return value;
        }
    }

    /**
     * 判断手机时候是否是iphoneX ，高度800+
     */
    isIphoneX() {
        const isIOS = this.isIphone();
        if (!isIOS) return false;
        // X XS, XS Max, XR,
        const xSeriesConfig = [
            {
                devicePixelRatio: 3,
                width: 375,
                height: 812,
            },
            {
                devicePixelRatio: 3,
                width: 414,
                height: 896,
            },
            {
                devicePixelRatio: 2,
                width: 414,
                height: 896,
            },
        ];  // h5
        if (typeof window !== 'undefined' && window) {
            const { devicePixelRatio, screen } = window;
            const { width, height } = screen;
            // console.log(`devicePixelRatio=${devicePixelRatio},screenWidth=${width},screenHeight=${height}`);
            return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
        }
        return false;
    }

    /**高度926 高度900+*/
    isIphoneXII() {
        const isIOS = this.isIphone();
        if (!isIOS) return false;
        //iphone12
        const xSeriesConfig = [
            {
                //iPhone12配置参数
                devicePixelRatio: 3,
                width: 428,
                height: 926,
            },
        ];  // h5
        if (typeof window !== 'undefined' && window) {
            const { devicePixelRatio, screen } = window;
            const { width, height } = screen;
            // console.log(`devicePixelRatio=${devicePixelRatio},screenWidth=${width},screenHeight=${height}`);
            return xSeriesConfig.some(item => item.devicePixelRatio === devicePixelRatio && item.width === width && item.height === height);
        }
        return false;
    }

    getPhoneWidthHeightRatio() {
        const { devicePixelRatio, screen } = window;
        const { width, height } = screen;
        return `devicePixelRatio=${devicePixelRatio},screenWidth=${width},screenHeight=${height}`;
    }

    /**
     * 是否是中融慧金iphoneX的app环境,需要做页面适配
     */
    isZrhuijinIphoneXApp() {
        return false;
        // return this.isIphoneX() && (/v_ios_app/i).test(window.navigator.userAgent);
    }

    isZrhuijinIphoneXIIApp() {
        return false;
        // return this.isIphoneXII() && (/v_ios_app/i).test(window.navigator.userAgent);
    }

    isZrhuijinAndroidApp() {
        return this.isAndroid() && (/v_android_app/i).test(window.navigator.userAgent);
    }

    isZrhuijinIphone6App() {
        return false;
        // return this.isIphone() && !this.isZrhuijinIphoneXApp() && (/v_ios_app/i).test(window.navigator.userAgent);
    }

    isJSCNIphoneNotWechat() {
        const { channel = '' } = Utils.fromQueryString();
        return channel === 'JSCN' && this.isIphone() && !this.isWechat();
    }

    findTenantCode(): any {
        const hosted = HostBy.asHostBy();
        let { tenantCode = hosted.tenantCode } = this.findAccount();
        return tenantCode;
    }
    isWechatShareGuide() {
		return this.isWechat();
	}
    /** 是否是江泰app环境 */
	isJiangtaiApp() {
		return (/appName\/sxj/i).test(window.navigator.userAgent);
	}
    /** 壹心 鲸云保app环境 */
    isZRBXApp() {
		return (/yxinsur/i).test(window.navigator.userAgent);
	}

    isMejIphoneAndIOS13() {
		const host = window.location.host;
		let ver = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
		if (ver) {
			let version = parseInt(ver[1], 10) || 14;
			if (host.indexOf('mej') > -1 && this.isIphone()) {
				if (version < 14) {
					return true;
				}
			}
		}
		return false;
	}

}

export default new Envs();
